import { translate } from 'vue-gettext';

const { gettext: $gettext } = translate;

export const businessLoan = {
  199: {
    name: 'businessLoanNoCompanyConnections',
    title: $gettext('Not sufficient business connections'),
  },
  200: {
    name: 'businessLoanComplete',
    title: $gettext('Corporate loan granted'),
    dataLayerEvents: {
      purchase: {
        ignoreOnPageRefresh: true,
        ignoreStepNumber: true,
        getters: {
          id: 'applicationId',
          revenue: 'revenue',
        },
        parameters: {
          quantity: 1,
        },
      },
      setApplicationStatus: {
        ignoreStepNumber: true,
        parameters: {
          status: 'completed',
        },
      },
    },
  },
  201: {
    name: 'businessLoanExpired',
    title: $gettext('The deadline has expired'),
  },
  202: {
    name: 'businessLoanPending',
    title: $gettext('Waiting for signatures'),
  },
  203: {
    name: 'businessLoanProcurationAbstractionRejection',
    title: $gettext('The application was suspended'),
    dataLayerEvents: {
      checkout: {
        parameters: {
          option: 'rejected',
        },
      },
      setApplicationStatus: {
        parameters: {
          status: 'reject',
        },
      },
    },
  },
  204: {
    name: 'businessLoanRejected',
    title: $gettext('The application was suspended'),
    dataLayerEvents: {
      checkout: {
        parameters: {
          option: 'rejected',
        },
      },
      setApplicationStatus: {
        parameters: {
          status: 'reject',
        },
      },
    },
  },
  205: {
    name: 'businessLoanSigned',
    title: $gettext('Signed'),
    dataLayerEvents: {
      purchase: {
        ignoreOnPageRefresh: true,
        ignoreStepNumber: true,
        getters: {
          id: 'applicationId',
          revenue: 'revenue',
        },
        parameters: {
          quantity: 1,
        },
      },
      setApplicationStatus: {
        ignoreStepNumber: true,
        parameters: {
          status: 'completed',
        },
      },
    },
  },
  206: {
    name: 'businessLoanSigningComplete',
    title: $gettext('Corporate loan granted'),
  },
  207: {
    name: 'businessLoanSigningPending',
    title: $gettext('Signature'),
  },
  208: {
    name: 'kreditz',
    title: $gettext('Verification of solvency'),
    dataLayerEvents: {
      checkout: {
        getters: {},
      },
      solvencyCheckStarted: {
        ignoreStepNumber: true,
        getters: {},
      },
      setApplicationStatus: {
        ignoreOnPageRefresh: true,
        ignoreStepNumber: true,
        parameters: {
          status: 'solvencyCheck',
        },
      },
    },
  },
};

export const carLoan = {
  299: {
    name: 'salesDetails',
    title: $gettext('Vehicle information'),
  },
  300: {
    name: 'complete',
    title: $gettext('Ready'),
  },
};

export default { businessLoan, carLoan };
